import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {
  ALAKERTA_DARK_BACKGROUND,
  ALAKERTA_GREEN,
  SECONDARY_FONT,
} from "../util/constants"

const NotFoundStyles = styled.div`
  min-height: 80vh;
  color: white;
  background: linear-gradient(${ALAKERTA_GREEN}, ${ALAKERTA_DARK_BACKGROUND});
  font-family: ${SECONDARY_FONT};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundStyles>
      <h1
        css={css`
          font-family: ${SECONDARY_FONT};
        `}
      >
        NOT FOUND
      </h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </NotFoundStyles>
  </Layout>
)

export default NotFoundPage
